import api from './api';
class CustomerService {
  create(customer) {
    return api
      .post('/customer/create', {
        firstName: customer.firstName,
        lastName: customer.lastName,
        address: customer.address,
        status: customer.status,
        postalCode: customer.postalCode,
        email: customer.email,
        phone: customer.phone,
      });
  }
  delete(customer_id) {
    return api
      .delete('/customer/' + customer_id);
  }
  get(customer_id) {
    return api
      .get('/customer/' + customer_id);
  }
  getAll() {
    return api
      .get('/customer/');
  }
  update(customer_id, customer) {
    return api.post('/customer/' + customer_id + '/update', {
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: customer.phone,
      email: customer.email,
      address: customer.address,
      postalCode: customer.postalCode
    });
  }
  updateStatus(customer_id, data) {
    return api.post('/customer/' + customer_id + '/status-update', {
      status: data.status,
    });
  }
  notifyUpdate(customer_id, timestamp) {
    return api.post('/customer/' + customer_id + '/notify-update', {
      timestamp
    });
  }
}
export default new CustomerService();
