<template>
  <router-view />
</template>
<style>
  html{
    height: 100%;
    scrollbar-color: #CDCDCD rgb(var(--v-theme-background));
  }
  body{
    min-height: 100%;
  }
  #app{
    min-height: 100vh;
    height:100%;
  }
  *{
    scrollbar-width: thin;
  }
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: rgb(var(--v-theme-background));
  }

  *::-webkit-scrollbar-thumb {
    background-color: #CDCDCD;
  }

  a{
    text-decoration: none;
    color: rgb(var(--v-theme-on-surface));
  }
</style>
<script>
import EventBus from "./common/EventBus";
export default {
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeUnmount() {
    EventBus.remove("logout");
  }
};
</script>
