<template>
  <v-card class="form-card">
    <v-card-title>
      <h4 class="title">
        Edit Profile
      </h4>
      <p class="category">
        Change your username, email, or password.
      </p>
    </v-card-title>
    <div class="wrapper">
      <v-card class="main-panel">
        <h3 class="title"> Change username/email </h3>
        <v-text-field hide-details label="Username" v-model="username" id="username">
        </v-text-field>
        <v-text-field hide-details label="Email Address" v-model="email" id="email">
        </v-text-field>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="v-raised v-success" @click="changeUsernameEmail()">
            Update Profile
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="main-panel">
        <h3 class="title"> Change password </h3>
        <v-text-field hide-details label="New Password" v-model="newPassword.password" id="password" type="password">
        </v-text-field>
        <v-text-field hide-details label="Confirm Password" v-model="newPassword.passwordConfirm" id="passwordConfirm" type="password">
        </v-text-field>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="v-raised v-success" @click="changeUserPassword()">
            Change Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
  .form-card{
    width: 100%;
    height: 100%;
    padding: 10px;
    background: rgb(var(--v-theme-on-surface-variant));
    box-shadow: none;
  }
  .wrapper{
    height:100%;
    background: rgb(var(--v-theme-on-surface-variant));
  }
  .v-card-title{
    background: rgb(var(--v-theme-on-surface-variant));
    display: block;
    padding-left: 8px;
    border-left: 3px solid #40a1c4;
  }
  .v-text-field{
    padding: 5px;
  }
  .main-panel{  
    padding: 10px;
    margin: 10px 0;
    background: rgb(var(--v-theme-background));
    border: 1px solid #40a1c4;
    .v-btn{
      box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    }
  }
  .category{
    font-weight: 400;
  }
</style>
<script>
import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
export default {
  name: "EditProfileForm",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      username: null,
      email: null,
      disabled: null,
      newPassword: {
        password: '',
        passwordConfirm: ''
      }
    };
  },
  mounted() {
    this.username = this.$store.state.auth.user.username;
    this.email = this.$store.state.auth.user.email;
  },
  methods: {
    changeUserPassword(){
      if(this.newPassword.password === this.newPassword.passwordConfirm){
        const creds = {username: this.username, password: this.newPassword.password};
        AuthService.changePass(creds)
        .then( () => {
          this.$notify({ 
            type: "success", 
            title: "Password changed successfully.",
          });
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
      }
    },
    changeUsernameEmail(){
      const creds = {currentUsername: this.$store.state.auth.user.username, username: this.username, email: this.email};
      AuthService.changeUsernameEmail(creds)
      .then( response => {
        this.username = response.data.username;
        this.email = response.data.email;
        this.$notify({ 
          type: "success", 
          title: "Username/email changed successfully.",
          text: "If you changed your username, login with your new username now."
        });
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      })
      .catch(e => {
        if (e.response && e.response.status === 403) {
          EventBus.dispatch("logout");
        }
      });
    }
  }
};
</script>
<style></style>
