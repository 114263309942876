<template>
  <div class="v-layout main-layout">
    <v-card class="customer-panel">
      <v-card-title data-background-color="blue">
        <!-- <div
          style="padding: 20px; width: 32px; height: 32px; border-radius: 32px; background: #40A1C4; ">
          <v-icon
            class="customer-icon"
            style="font-size: 32px; margin-right: 5px; color: white;">
            mdi-account
          </v-icon>
        </div> -->
        <div
          class="customer-header">
          <h3 class="title">
            Customer
          </h3>
          <p class="category">
            {{ customer.firstName }} {{ customer.lastName }}
          </p>
        </div>
      </v-card-title>
      <div class="v-layout main-layout">
        <v-card class="customer v-layout-item v-xsmall-size-100 v-small-size-100 v-medium-size-100 v-large-size-100 v-size-100">
          <v-card-actions class="main-action">
            <router-link
              to="/customers"
            >
              <v-btn
                style="background-color: #40A1C4; box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);"
                color="white"
                ml-10
              >
                Back to Customer List
              </v-btn>
            </router-link>
          </v-card-actions>
          <v-card-text id="main-panel">
            <div class="status">
                <v-btn-toggle
                  v-model="customer.status"
                  color="primary"
                  mandatory
                  v-if="!isMobile()"
                >
                  <v-btn prepend-icon="mdi-emoticon-happy-outline" value="good" color="#3DC13C" @click="() => updateCustomerStatus()">
                      GOOD
                  </v-btn>
                  <v-btn prepend-icon="mdi-clock-outline" value="waiting" color="#F3BB1B" @click="() => updateCustomerStatus()">
                      WAITING
                  </v-btn>
                  <v-btn prepend-icon="mdi-shovel" value="in progress" color="#3F66FB" @click="() => updateCustomerStatus()">
                      IN PROGRESS
                  </v-btn>
                  <v-btn prepend-icon="mdi-emoticon-sad-outline" value="upset" color="#F13637" @click="() => updateCustomerStatus()">
                      UPSET
                  </v-btn>
                </v-btn-toggle>
                <v-btn-toggle
                  v-model="customer.status"
                  color="primary"
                  mandatory
                  v-else
                >
                  <v-btn icon="mdi-emoticon-happy-outline" value="good" color="#3DC13C" @click="() => updateCustomerStatus()">
                  </v-btn>
                  <v-btn icon="mdi-clock-outline" value="waiting" color="#F3BB1B" @click="() => updateCustomerStatus()">
                  </v-btn>
                  <v-btn icon="mdi-shovel" value="in progress" color="#3F66FB" @click="() => updateCustomerStatus()">
                  </v-btn>
                  <v-btn icon="mdi-emoticon-sad-outline" value="upset" color="#F13637" @click="() => updateCustomerStatus()">
                  </v-btn>
                </v-btn-toggle>
            </div>
            <v-card class="panel">
              <h3 class="title">
                Customer Interactions
              </h3>
              <div class="v-card-content panel-content" v-if="interactions !== ''">
                <v-hover
                  v-slot="{ isHovering, props }"
                  v-for="(logMessage, index) in interactions.slice().reverse()"
                  :key="logMessage._id"
                >
                  <v-card class="log-message"
                  :style="{border: logMessage.priority === 'HIGH' ? '2px solid #F13637' : logMessage.priority === 'MEDIUM' ? '2px solid #F3BB1B' : ''}"
                  :class="{ 'on-hover': isHovering }"
                  v-bind="props">
                    <v-card-header
                      class="timestamp"
                    >
                      <p class="timestamp-text">
                        {{logMessage.authorName + " - " + formatDate(new Date(logMessage.updatedAt)) }}
                      </p>
                    </v-card-header>
                    <div>{{logMessage.message}}</div>
                    <div 
                      v-if="logMessage.images.length" 
                      style="display:inline-flex; flex-wrap: wrap; cursor: pointer;">
                      <div v-for="(imageName, imageIndex) in logMessage.images" v-bind:key="imageIndex">
                        <v-card class="ma-1"
                        @click="openImageModal(interactions.length - index, imageIndex, logMessage.images)">
                          <v-img style="border-radius: inherit; background: rgb(var(--v-theme-surface-variant));" :aspect-ratio="1" width="75" :src="'https://api.miramichipool.com/api/upload/files/' + imageName" :id="imageName">
                            <template v-slot:placeholder>
                              <div class="d-flex align-center justify-center fill-height">
                                <v-progress-circular
                                  indeterminate
                                  color="#40A1C4"
                                ></v-progress-circular>
                              </div>
                            </template>
                          </v-img>
                        </v-card>
                      </div>
                    </div>
                    <v-dialog
                      v-model="imageActive[interactions.length - index]">
                      <v-card id="image-card" class="px-3">
                        <div id="image-frame">
                          <div class="btn-div">
                            <v-btn
                              :size="isMobile() ? 'small' : 'large'"
                              icon="mdi-arrow-left-thick"
                              @click="prevImageModal(logMessage.images, activeImage.idx)"
                              :disabled="activeImage.idx - 1 < 0">
                            </v-btn>
                          </div>
                          <v-spacer/>
                          <div class="ma-3">
                            <img style="max-width:100%;" :src="'https://api.miramichipool.com/api/upload/files/' + activeImage.image" :id="activeImage.image"/>
                          </div>
                          <v-spacer/>
                          <div class="btn-div">
                            <v-btn
                              :size="isMobile() ? 'small' : 'large'"
                              icon="mdi-arrow-right-thick"
                              @click="nextImageModal(logMessage.images, activeImage.idx)"
                              :disabled="activeImage.idx + 1 > logMessage.images.length-1">
                            </v-btn>
                          </div>
                        </div>
                      </v-card>
                    </v-dialog>
                    <v-btn
                    :class="isHovering ? 'hovering delete-button' : 'delete-button'"
                    @click="deleteInteractionActive[interactions.length - index] = true;"
                    icon="mdi-trash-can-outline"
                    size="x-small">
                    </v-btn>
                    <v-dialog
                      v-model="deleteInteractionActive[interactions.length - index]">
                      <v-card id="delete-card">
                        <h3>Delete {{logMessage.authorName}}'s interaction with {{ customer.firstName }} {{ customer.lastName }}?</h3>
                        <p>Are you sure you want to delete this interaction?</p>
                        <v-card-actions>
                          <v-btn @click="deleteInteractionActive[interactions.length - index] = false;"
                          style="color: rgba(var(--v-theme-on-surface),var(--v-high-emphasis-opacity));">
                            CANCEL
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn @click="deleteInteraction(logMessage._id)"
                          style="background-color: #F13637;">
                            DELETE
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card>
                </v-hover>
              </div>
            </v-card>
            <v-card class="panel">
              <h3 class="title">
                Create New Interaction
              </h3>
              <v-textarea 
                outlined
                name="interaction-text"
                label="Record a customer interaction..."
                v-model="newInteraction"
                hide-details
                :disabled="interactionCreating"
                flat 
                solo
              />
                <v-dialog 
                  v-model="uploadActive"
                >
                  <v-card class="update-customer-card">
                    <h3>Attach photo(s) to interaction</h3>
                    <v-file-input
                      accept="image/*"
                      multiple
                      label="Upload image(s)..."
                      @change="selectFiles"
                      type="file"
                      hide-details
                      style="width:100%;"
                    ></v-file-input>
                    <v-card-actions>
                        <v-btn @click="uploadActive = false; selectedFiles = undefined;"
                        style="color: rgba(var(--v-theme-on-surface),var(--v-high-emphasis-opacity));">
                          CANCEL
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="uploadActive = false;"
                        style="background-color: #40A1C4;">
                          ATTACH
                        </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <div
                class="add-photos">
                  <v-btn
                    @click="uploadActive = true;"
                    icon="mdi-image-plus"
                    :disabled="interactionCreating"
                  >
                  </v-btn>
                  <div v-if="selectedFiles" class="selected-count">{{ selectedFiles.length }}</div>
                </div>
              <v-card-actions
              >
                <v-select
                  :items="prioritySelections"
                  label="Priority"
                  v-model="selectedPriority"
                  style="max-width:132px;"
                  hide-details="auto"
                  :disabled="interactionCreating"
                  flat
                  solo
                ></v-select>
                <v-spacer />
                <v-btn @click="uploadFilesAndCreateInteraction()"
                  :disabled="interactionCreating">
                  Submit
                </v-btn>
              </v-card-actions>
              <div class="interaction-progress"
                v-if="interactionCreating && selectedFiles">
                <v-progress-circular
                  indeterminate
                  color="#40A1C4"
                ></v-progress-circular>
                <p class="my-1">{{uploadedFiles}} of {{ selectedFiles.length }} image(s) uploaded.</p>
              </div>
            </v-card>
            <v-card class="panel">
              <h3 class="title">
                Customer Information
              </h3>
              <p class="info"><strong>Name:</strong> {{ customer.firstName }} {{ customer.lastName }}</p>
              <p class="info"><strong>Phone Number:</strong> {{ customer.phone }}</p>
              <p class="info"><strong>Email:</strong> {{ customer.email }}</p>
              <p class="info"><strong>Address:</strong> {{ customer.address }}</p>
              <p class="info"><strong>Postal Code:</strong> {{ customer.postalCode }}</p>
              <v-card-actions
                layout="row"
              >
                <v-dialog 
                  v-model="updateActive"
                >
                  <v-card class="update-customer-card">
                    <h3>Update Customer Info</h3>
                    <div style="display:flex;">
                      <v-text-field hide-details label="* First Name" class="name" id="firstName" v-model="customerUpdate.firstName" required>
                      </v-text-field>
                      <v-text-field hide-details label="* Last Name"  class="name" id="lastName" v-model="customerUpdate.lastName" required>
                      </v-text-field>
                    </div>
                    <v-text-field hide-details label="Email" v-model="customerUpdate.email" id="email">
                    </v-text-field>
                    <v-text-field hide-details label="Phone Number" v-model="customerUpdate.phone" id="phone">
                    </v-text-field>
                    <v-text-field hide-details label="Address" v-model="customerUpdate.address" id="address">
                    </v-text-field>
                    <span class="v-caption">* = required field</span>
                    <v-card-actions>
                        <v-btn @click="updateActive = false;"
                        style="color: rgba(var(--v-theme-on-surface),var(--v-high-emphasis-opacity));">
                          CANCEL
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="updateCustomer();"
                        style="background-color: #40A1C4;">
                          UPDATE
                        </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <v-btn
                  data-background-color="blue"
                  @click="updateActive = true"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
          <v-card-actions class="main-action">
            <v-dialog
              v-model="deleteActive">
              <v-card id="delete-card">
                <h3>Delete Customer {{ customer.firstName }} {{ customer.lastName }}?</h3>
                <p>Are you sure you want to delete this customer? This action is final and cannot be reverted.</p>
                <v-card-actions>
                  <v-btn @click="deleteActive = false;"
                  style="color: rgba(var(--v-theme-on-surface),var(--v-high-emphasis-opacity));">
                    CANCEL
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="deleteCustomer()"
                  style="background-color: #F13637;">
                    DELETE
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-btn
              @click="deleteActive = true"
              style="background-color: #F13637;"
            >
              DELETE CUSTOMER
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<style lang="scss">
  >>> .v-input__slot::before {
    border-style: none !important;
  }
  .v-btn.delete-button{
    display:none;
    position: absolute;
    right: 8px;
    top:50%;
    transform: translateY(-50%);
  }

  .v-btn.delete-button.hovering{
    display: block;
  }
  .customer-panel{
    width: 100%;
    height: 100%;
    background: rgb(var(--v-theme-on-surface-variant));
  }
  .update-customer-card{
    position:relative;
    margin: 10px auto;
    padding:5px 20px;
    min-width:330px;
    width:33vw;
    .name{
      position:relative;
      min-width:50%;
      width: 50%;
    }
    .v-text-field{
      padding: 5px;
    }
  }
  .v-card-title{
    padding: 10px;
    display:flex;
    border-left: 3px solid #40a1c4;
    margin-left: 15px;
    margin-bottom: 15px;
  }
  .status-option{
    width:100%;
    display: block;
    text-align: center;
    margin-bottom:0;
  }
  #important-checkbox{
    display: inline-flex;
    line-height:56px;
  }
  #important-checkbox .v-checkbox {
    display: flex;
  }
  .v-card .customer {
    position: relative;
    margin: 10px auto;
    margin-top:0px;
    padding: 0 5px;
    width:100%;
    background: rgb(var(--v-theme-on-surface-variant));
    box-shadow:none;
  }
  .status{
    text-align: left;
    .good{
      border-radius:5px;
      background-color: #3DC13C !important;
      font-weight: 600;
    }
    .upset{
      border-radius:5px;
      background-color: #F13637 !important;
      font-weight: 600;
    }
    .waiting{
      padding:0;
      border-radius:5px;
      background-color: #F3BB1B !important;
      font-weight: 600;
    }
    .in-progress{
      border-radius:5px;
      background-color: #3F66FB !important;
      font-weight: 600;
    }
  }

  #main-panel{
    padding: 10px 5px;
    background: rgb(var(--v-theme-on-surface-variant));
    .title{
      font-size: 1.1rem;
      margin-bottom: 5px;
    }
    .v-card-actions{
      .v-btn{
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
      }
    }
  }

  .panel{
    background: rgb(var(--v-theme-background));
    padding:10px;
    margin: 10px 0;
    border: 1px solid #40a1c4;
    .panel-content{
      overflow-y: scroll;
      height: 300px;
    }
    .info{
      line-height: 1.8;
    }
  }

  .log-message{
    padding: 10px;
    margin: 18px 0;
    background: rgb(var(--v-theme-on-surface-variant));
    white-space: pre-wrap;
  }

  .v-card .timestamp{
    padding:0;
    font-size:12px;
    margin-top: -20px;
  }

  .v-card .timestamp .timestamp-text{
    background: rgb(var(--v-code-background-color));
    border-radius: 4px;
    padding: 2px 5px;
  }

  .main-layout{
    overflow:unset;
    background: rgb(var(--v-theme-on-surface-variant));
  }

  a{
    color: black;
    text-decoration: unset;
  }

  .category{
    font-weight: 400;
  }

  .v-card-actions{
    background: rgb(var(--v-theme-background));
    border-radius: 4px;
  }

  .v-dialog{
    .v-card-actions{
      background: rgb(var(--v-theme-surface));
    }
  }
  .main-action{
    padding: 5px;
    background: rgb(var(--v-theme-on-surface-variant));
    box-shadow:none;
  }
  #delete-card{
    position:relative;
    margin: 10px auto;
    padding:5px 20px;
    min-width:330px;
    width:33vw;
    .v-card-actions{
      background: rgb(var(--v-theme-on-surface-variant));
    }
  }
  .v-btn--selected{
    color: rgb(var(--v-theme-surface));
  }
  .v-btn-group{
    border: 1px solid #40a1c4;
  }
  .add-photos{  
    position: absolute;
    right: 15px;
    margin-top: -56px;
    .selected-count{
      position:absolute;
      top:0px;
      left: -5px;
      font-size: 12px;
      width:20px;
      height: 20px;
      line-height: 20px;
      background-color: #F13637;
      color: rgb(var(--v-theme-surface));
      border-radius:20px;
      text-align:center;
    }
  }
  
  #image-card{
    background: rgb(var(--v-theme-surface-variant));
    position:relative;
    margin: 10px auto;
    min-width:350px;
    width:50vw;
    min-height:300px;
    #image-frame{
      display:flex;
      align-items: stretch;
      flex-direction: row;
      margin: auto 0;
      .btn-div{
        display:flex;
        align-items: stretch;
        .v-btn{
          margin: auto 0;
        }
      }
    }
    .v-card-actions{
      background: rgb(var(--v-theme-surface-variant));
      .v-btn{
        background: rgb(var(--v-theme-surface));
      }
    }
  }

  .interaction-progress{ 
    display:block;
    text-align:center; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
</style>

<script>
import CustomerService from "../services/customer.service";
import InteractionService from "../services/interaction.service";
import UploadService from "../services/upload.service";
import EventBus from "../common/EventBus";
import moment from 'moment';

export default {
  name:"CustomerPanel",
  data() {
    return {
      selectedFiles: undefined,
      message: "",
      fileInfos: [],
      newFiles: [],
      newFileNames: [],
      customer: '',
      interactions: [],
      customerUpdate: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        postalCode: '',
      },
      closeOnClick: false,
      closeOnSelect: true,
      newInteraction: '',
      important: false,
      interactionCreating: false,
      deleteActive: false,
      deleteInteractionActive: [],
      imageActive: [],
      activeImage: {
                    idx:-1,
                    image:'',
                    },
      updateActive: false,
      uploadActive: false,
      uploadedFiles: 0,
      uploadProgress: 0,
      value: null,
      selectedPriority: 'LOW',
      prioritySelections: ['LOW', 'MEDIUM', 'HIGH'],
      months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
              ],
      days: [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday'
            ],
    };
  },
  methods: {
    formatDate(dateString) {
        return moment(dateString).format('MMMM Do YYYY @ h:mm:ss a');
    },
    // Customer
    getCustomer(id) {
      CustomerService.get(id)
        .then( response => {
          this.customer = response.data;

          this.customerUpdate.firstName = this.customer.firstName;
          this.customerUpdate.lastName = this.customer.lastName;
          this.customerUpdate.email = this.customer.email;
          this.customerUpdate.phone = this.customer.phone;
          this.customerUpdate.address = this.customer.address;
          this.customerUpdate.postalCode = this.customer.postalCode;
          
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },
    updateCustomer() {
      var data = {
        firstName: this.customerUpdate.firstName,
        lastName: this.customerUpdate.lastName,
        email: this.customerUpdate.email,
        phone: this.customerUpdate.phone,
        address: this.customerUpdate.address,
        postalCode: this.customerUpdate.postalCode,
      }
      CustomerService.update(this.$route.params.customer_id, data)
        .then( response => {
          this.customer = response.data.customer;
          this.updateActive = false;
          this.$notify({ 
            type: "success", 
            title: "User updated successfully!",
          });
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },
    updateCustomerStatus() {
      var data = {
        status: this.customer.status,
      }
      CustomerService.updateStatus(this.$route.params.customer_id, data)
        .then( response => {
          this.customer = response.data.customer;
          this.$notify({ 
            type: "success", 
            title: "Customer status updated!",
            duration: 10000,
          });
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },
    notifyCustomerUpdate(timestamp) {
      CustomerService.notifyUpdate(this.$route.params.customer_id, timestamp)
        .then( response => {
          this.customer = response.data.customer;
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },
    deleteCustomer() {
      CustomerService.delete(this.$route.params.customer_id)
        .then( () => {
          this.deleteActive = false;
          this.$notify({ 
            type: "success", 
            title: "Customer deleted successfully!",
          });
          this.$router.push("/customers/" );
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },

    // Interactions
    getInteractions() {
      InteractionService.get(this.$route.params.customer_id)
        .then( response => {
          this.interactions = response.data.interactions;
          this.interactions.forEach((interaction,index) => {this.deleteInteractionActive[this.interactions.length - index] = false; this.imageActive[this.interactions.length - index] = false;});
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },
    async createInteraction(){
      const user = this.$store.state.auth.user;
      var data;
      if(this.selectedFiles) {
        this.selectedFiles = undefined;

        await this.getLatestFiles();
        
        data = {
          customerId: this.$route.params.customer_id,
          author: user,
          message: this.newInteraction,
          priority: this.selectedPriority,
          images: this.newFileNames,
        };

      }else{
        data = {
          customerId: this.$route.params.customer_id,
          author: user,
          message: this.newInteraction,
          priority: this.selectedPriority,
        };
      }
      InteractionService.create(data)
        .then(response => {
          this.interactions.push(response.data.interaction);
          this.newInteraction = '';
          this.selectedPriority = 'LOW';
          this.deleteInteractionActive.push(false);
          if(data.images)
            this.imageActive.push(false);
          
          this.$notify({ 
            type: "success", 
            title: "Interaction created successfully!",
          });
          this.notifyCustomerUpdate(response.data.interaction.createdAt);
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },
    deleteInteraction(id) {
      this.deleteInteractionActive.forEach(function(part, index, deleteDialogArray) {
        deleteDialogArray[index] = false;
      });
      this.imageActive.forEach(function(part, index, imageDialogArray) {
        imageDialogArray[index] = false;
      });
      this.deleteInteractionActive.pop();
      this.imageActive.pop();

      InteractionService.delete(id)
        .then( () => {
          this.interactions = this.interactions.filter(interaction => interaction._id !== id);
          this.$notify({ 
            type: "success", 
            title: "Interaction deleted successfully!",
          });
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },

    // Other
    onConfirm () {
      this.value = 'delete'
    },
    onCancel () {
      this.value = 'don\'t delete'
    },
     isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    // IMAGE UPLOAD
    selectFiles(event) {
      this.selectedFiles = event.target.files;
    },
    async upload(idx, file) {
      await UploadService.upload(file)
        .then((response) => {
          let prevMessage = this.message ? this.message + "\n" : "";
          this.message = prevMessage + response.data.message;
          this.uploadedFiles++;
          this.uploadProgress = Math.floor((this.uploadedFiles/this.selectedFiles.length)*100);
          return UploadService.getFiles();
        })
        .catch(() => {
          this.message = "Could not upload the image:" + file.name;
        });
    },

    async uploadFilesAndCreateInteraction() {
      this.message = "";
      this.interactionCreating = true;
      this.uploadedFiles = 0;
      this.uploadProgress = 0;
      if(this.selectedFiles){
        for (let i = 0; i < this.selectedFiles.length; i++) {
          await this.upload(i, this.selectedFiles[i]);
        }
      }
      await this.createInteraction();
      this.interactionCreating = false;
    },

    async getLatestFiles() {
      await UploadService.getFiles().then((response) => {
        const beforeFiles = this.fileInfos;
        const afterFiles = response.data;

        const isSameFile = (a, b) => a.name === b.name;
        const newFileFilter = (left, right, compareFunction) => 
          left.filter(leftValue =>
            !right.some(rightValue => 
              compareFunction(leftValue, rightValue)));
        

        this.newFiles = beforeFiles ? newFileFilter(afterFiles, beforeFiles, isSameFile) : afterFiles;
        this.newFileNames = this.newFiles.map(a => a.name);

        this.fileInfos = response.data;
      });
    },

    openImageModal(idx, imageIdx, images) {
      this.activeImage.idx = imageIdx;
      this.activeImage.image = images[this.activeImage.idx];
      this.imageActive[idx] = true;
    },
    nextImageModal(images, currentIdx) {
      if(currentIdx + 1 > images.length - 1){
        return;
      }
      this.activeImage.idx = currentIdx + 1;
      this.activeImage.image = images[this.activeImage.idx];
    },
    prevImageModal(images, currentIdx) {
      if(currentIdx - 1 < 0){
        return;
      }
      this.activeImage.idx = currentIdx - 1;
      this.activeImage.image = images[this.activeImage.idx];
    },
  },
  mounted(){
    this.getCustomer(this.$route.params.customer_id);
    this.getInteractions();
    UploadService.getFiles().then((response) => {
      this.fileInfos = response.data;
    });
  },
};
</script>
