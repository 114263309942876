import api from './api';
class InteractionService {
  create(interaction) {
    return api
      .post('/interaction/create', {
        customerId: interaction.customerId,
        userId: interaction.author.id,
        message: interaction.message,
        priority: interaction.priority,
        images: interaction.images,
      });
  }
  get(customer_id) {
    return api
      .get('/interaction/' + customer_id);
  }
  delete(interaction_id) {
    return api
      .delete('/interaction/' + interaction_id);
  }
}
export default new InteractionService();
