<template>
  <v-layout>
    <v-card class="customers-panel">
      <v-card-title :class="isMobile() ? 'mobile' : ''">
        <h3 class="title">
          Customer List
        </h3>
        <p class="category">
          View customers, or add new customers to the system.
        </p>
      </v-card-title>
      <v-card-actions id="new-customer" :class="isMobile() ? 'mobile' : ''">
        <v-dialog 
          v-model="active"
        >
          <v-card id="new-customer-card">
            <h3>Create New Customer</h3>
            <div style="display:flex;">
              <v-text-field hide-details label="* First Name" class="name" id="firstName" v-model="newCustomer.firstName" required>
              </v-text-field>
              <v-text-field hide-details label="* Last Name"  class="name" id="lastName" v-model="newCustomer.lastName" required>
              </v-text-field>
            </div>
            <v-text-field hide-details label="Email" v-model="newCustomer.email" id="email">
            </v-text-field>
            <v-text-field hide-details label="Phone Number" v-model="newCustomer.phone" id="phone">
            </v-text-field>
            <v-text-field hide-details label="Address" v-model="newCustomer.address" id="address">
            </v-text-field>
            <v-text-field hide-details label="Postal Code" v-model="newCustomer.postalCode" id="postalCode">
            </v-text-field>
            <span class="v-caption">* = required field</span>
            <v-card-actions>
                <v-btn @click="active = false;"
                style="color: rgba(var(--v-theme-on-surface),var(--v-high-emphasis-opacity));">
                  CANCEL
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="createCustomer();"
                style="background-color: #40A1C4;">
                  CREATE
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn @click="active = true"
          style="background-color: #40A1C4; "
        >
          New Customer
        </v-btn>
      </v-card-actions>
      <v-layout id="customer-list">
        <v-card
          v-for="(customer,index) in customers"
          :key="index"
          :class="isMobile() ? 'customer mobile' : 'customer'"
          :style="isMobile() ? 'width: 98%' : 'width: 49%;'"
        >
          <router-link :to="'/customer/' + customer._id">
            <div class="v-card-content">
              <div class="v-title">
                {{ customer.firstName + " " + customer.lastName }}
              </div>
              <v-spacer/>
              <div class="status">
                <v-icon class='good' v-if="customer.status === 'good'">mdi-emoticon-happy-outline</v-icon>
                <v-icon class='waiting' v-else-if="customer.status === 'waiting'">mdi-clock-outline</v-icon>
                <v-icon class='in-progress' v-else-if="customer.status === 'in progress'">mdi-shovel</v-icon>
                <v-icon class='upset' v-else-if="customer.status === 'upset'">mdi-emoticon-sad-outline</v-icon>
              </div>
            </div>
            <v-card-actions
              layout="row"
              class="customer-actions"
            >
              <div class="last-update">
                Updated: {{ formatDate(customer.updatedAt) }}
              </div>
              <v-spacer/>
              <v-btn 
                :to="'/customer/' + customer.id"
                style="background-color: #40A1C4;"
                color="white"
              >
                OPEN
              </v-btn>
            </v-card-actions>
          </router-link>
        </v-card>
        <div 
        class="filter-empty-space">
        </div>
      </v-layout>
    </v-card>
  </v-layout>
</template>

<style lang="scss" scoped>
  .customers-panel{
    width: 100%;
    height: 100%;
    background: rgb(var(--v-theme-on-surface-variant));
  }
  #new-customer{
    background: inherit;
    margin: 20px;
    padding:0;
    border-top:0;
  }

  #new-customer.mobile{
    margin:15px;
  }

  #new-customer-card{
    padding:5px 20px;
    min-width:330px;
    width:33vw;
    .name{
      position:relative;
      min-width:50%;
      width: 50%;
    }
    .v-text-field{
      padding: 5px;
    }
  }

  div.v-layout{
    display:flex;
    justify-content: space-around;
  }

  #customer-list{
    width:100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    overflow-y:visible;
  }
  .v-card-title{
    display:block;
    padding: 8px;
    border-left: 3px solid #40a1c4;
    margin-left: 20px;
  }

  .v-card-title.mobile{
    margin-left:15px;  
  }
  .v-card.customer {
    position:relative;
    margin-top: 8px;
    margin-bottom: 8px;
    background: rgb(var(--v-theme-background));
    border: 1px solid #40a1c4;
    .last-update{
      font-size:0.85rem;
      margin-top:auto;
    }
    .v-card-content{
      display:flex;
      padding: 8px;
    }
    .v-title{
      font-size: 1.3rem;
    }
    .customer-actions{
      background: inherit;
    }
  }

  .customer:not(.mobile){
    min-width:400px;
  }

  .customer:nth-child(odd):not(.mobile){
    margin-left: 8px;
    margin-right: 4px;
  }

  .customer:nth-child(even):not(.mobile){
    margin-left: 4px;
    margin-right: 8px;
  }
  .v-card.customer.mobile{
    margin: 8px 16px;
  }
  
  .status{
    text-align: left;
    color: rgba(var(--v-theme-on-surface),var(--v-high-emphasis-opacity));
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    border-radius:16px;
    .good{
      padding:16px;
      border-radius:inherit;
      background-color: #3DC13C !important;
      color:white;
      font-weight: 500;
    }
    .upset{
      padding:16px;
      border-radius:inherit;
      background-color: #F13637 !important;
      color:white;
      font-weight: 500;
    }
    .waiting{
      padding:16px;
      border-radius:inherit;
      background-color: #F3BB1B !important;
      color:white;
      font-weight: 500;
    }
    .in-progress{
      padding:16px;
      border-radius:inherit;
      background-color: #3F66FB !important;
      color:white;
      font-weight: 500;
    }
  }

  a{
    color: rgba(var(--v-theme-on-surface),var(--v-high-emphasis-opacity));
    text-decoration: unset;
  }

  .v-btn{
    color: white;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  }

  .category{
    font-weight: 400;
  }
  .v-dialog .v-overlay__content{
    margin:0;
  }
  .filter-empty-space{
    height: 0;
    width: 49%;
    margin-left: 4px;
    margin-right: 8px;
  }
</style>

<script>
import CustomerService from "../services/customer.service";
import EventBus from "../common/EventBus";
import moment from 'moment';
export default {
  name:"CustomersPanel",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active: false,
      customers: '',
      newCustomer: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        postalCode: "",
      }
    };
  },
  methods:{
    formatDate(dateString) {
        return moment(dateString).format('MMMM Do YYYY @ h:mm:ss a');
    },
    getAllCustomers() {
      CustomerService.getAll()
        .then( response => {
          this.customers = response.data.customers;
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },
    createCustomer(){
      var data = {
        firstName: this.newCustomer.firstName,
        lastName: this.newCustomer.lastName,
        email: this.newCustomer.email,
        phone: this.newCustomer.phone,
        address: this.newCustomer.address,
        postalCode: this.newCustomer.postalCode,
      };
      CustomerService.create(data)
        .then(response => {
          this.active = false;
          this.$notify({ 
            type: "success", 
            title: "Customer " + data.firstName + " " + data.lastName + " created successfully!",
          });
          this.$router.push("/customer/" + response.data.customer_id);
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            EventBus.dispatch("logout");
          }
        });
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  mounted(){
    this.getAllCustomers();
  }
};
</script>
