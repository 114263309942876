<template>
    <div>
        <v-btn @click="hideWeekends = !hideWeekends">
            {{hideWeekends ? 'Show' : 'Hide'}} Weekends?
        </v-btn>
        <vue-cal class="vuecal--blue-theme" :events="events" active-view="month" :time-from="10 * 60" :hide-weekends="hideWeekends" events-on-month-view="short" :disable-views="['years', 'year', 'week']">
            <template v-slot:event="{ event }">
            <div class="vuecal__event-title" v-html="event.customer + ' - ' + event.title"></div>
            <em class="vuecal__event-time"><span>{{ event.start.formatTime() }} - {{ event.end.formatTime() }}</span></em>
            </template>
        </vue-cal>
    </div>
</template>
<style lang="scss">
    .vuecal__cell{
        min-height:75px;
    }
    .vuecal__cell-events {
        width: 90%;
        margin: 0 auto;
        .vuecal__event {
            margin-bottom:2px;
            border-radius:4px;
            border: 1px solid rgba(115,191,204,.5);
            .vuecal__event-title {
                text-align:center;
            }
        }
    }
    .day-view{
        .vuecal__event{
            width: 90%;
            margin: 0 auto;
        }
    }

    .month-view{
        .vuecal_cell-content{
            justify-content: flex-start;
            height: 100%;
            align-items: flex-end;
        }
        .vuecal__cell-date {padding: 4px;}
        .vuecal__no-event {display: none;}
    }
</style>
<script>
// In your Vue.js component.
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

export default {
  name: 'SchedulePanel',
  components: { VueCal },
  data: () => ({
      events: [
      {
        start: '2022-05-6 10:35',
        end: '2022-05-6 11:30',
        title: 'Hot-tub install',
        customer: 'Jack Frost'
      },
      {
        start: '2022-05-19 10:35',
        end: '2022-05-19 13:30',
        title: 'Pool install',
        customer: 'John Deere'
      },
      {
        start: '2022-05-19 11:30',
        end: '2022-05-19 19:15',
        title: 'Meeting with Master Spas',
        customer: 'Master Spa'
      },
      {
        start: '2022-05-20 18:30',
        end: '2022-05-20 20:30',
        title: 'Chemical Delivery',
        customer: 'Sue Loo'
      },
      {
        start: '2022-05-21 11:00',
        end: '2022-05-21 13:00',
        title: 'Provide quote for pool installation',
        customer: 'Bill Gates'
      },
      {
        start: '2022-05-21 12:30',
        end: '2022-05-21 15:15',
        title: 'Swim spa installation',
        customer: 'Elon Musk'
      }
    ],
    hideWeekends: false,
  }),
  methods:{
     isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
  },
  mounted(){
      this.hideWeekends = this.isMobile() ? true : false;
  }
}
</script>