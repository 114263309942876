import api from "./api";

class UploadService {
  upload(file) {
    let formData = new FormData();

    formData.append("file", file);

    return api.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  getFiles() {
    return api.get("/upload/files");
  }
}

export default new UploadService();